<template>
    <div>
        <div class="control has-icons-left is-medium" id='searchBoxContainer' :class="{'expanded': active}">
            <input ref="searchBox" class="input search-box" type="text" autocomplete="nope" placeholder="Search Clubber Names..." v-model="query" 
                @focus="grow()" @blur="shrink()" @keyup.esc="$refs.searchBox.blur()" 
                @keyup.up="tabIdxUp()" @keyup.down="tabIdxDown()" 
                @keyup.enter="goToClubber(searchResults[focusId].id)"
            />
            <b-icon icon="magnify" size="is-small" class="is-left" />
            <div class="search-results" :class="{'active': resultsBoxVisible}" @mouseover="mouseover=true" @mouseout="mouseover=false">{{searchResultsMsg}}<br/><br/>
                <div class="search-result-full is-clickable" :class="{'focused': focusId === i}"
                    v-for="(result, i) in searchResults" :key="i" @click="goToClubber(result.id)" 
                >
                    <div class="content result" :class="clubClass(result)">
                        <h3>{{result.fullName}}</h3>
                        <div>
                            <div>
                                <i class="fas fa-phone"></i> {{result.family.phone1}} 
                                <span v-if="result.family.phone1note.length > 0">({{ result.family.phone1note }})</span>
                            </div>
                            <div>
                                <i class="fas fa-phone"></i> {{result.family.phone2}} 
                                <span v-if="result.family.phone2note.length > 0">({{ result.family.phone2note }})</span>
                            </div>
                            <div>
                                <i class="fas fa-phone"></i> {{result.family.phone3}} 
                                <span v-if="result.family.phone3note.length > 0">({{ result.family.phone3note }})</span>
                            </div>
                            <i class="fas fa-address-book"></i> {{result.family.address.str}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                active: this.$mq==='desktop' ? false : true,
                query: "",
                clubberList: [],
                loading: false,
                searchResultsMsg: "",
                mouseover: false,
                resultsBoxVisible: false,
                focusId: 0
            }
        },

        mounted() {
            this.active = this.$mq === 'desktop' ? false : true;
        },

        methods: {
            loadClubbers() {
                this.searchResultsMsg = 'Loading Clubbers...';
                const clubberFields = 'id,fullName,club';
                const familyFields = 'id,phone1,phone1note,phone2,phone2note,phone3,phone3note,address';
                this.$http.get(`${this.$api}/clubbers?clubberFields=${clubberFields}&familyFields=${familyFields}`).then(response => {
                    this.clubberList = response.data;
                    this.searchResultsMsg = "Please enter a search query...";
                }).catch(error => {
                    this.$root.$emit('handle-error', error);
                });
            },

            grow() {
                this.active = true;
                this.searchResultsMsg = "Please enter a search query...";
                this.focusId = 0;
                this.loadClubbers();
                setTimeout(() => {
                    this.resultsBoxVisible = true;
                }, 250);
            },

            shrink() {
                if(!this.mouseover) {
                    this.resultsBoxVisible = false;
                    this.query = "";
                    this.searchResultsMsg = "";
                    setTimeout(() => {
                        this.active = false;
                    }, 250);
                    this.$emit('search-box-closed');
                }
            },

            goToClubber(clubberId) {
                this.$router.push('/viewClubber/'+clubberId);
                this.mouseover = false;
                this.shrink();
            },

            clubClass(clubber) {
                if (clubber.club === 'T&T') return 'TnT';
                else return clubber.club;
            },

            tabIdxUp() {
                if (this.focusId === 0) return;
                this.focusId--;
            },

            tabIdxDown() {
                if (this.focusId >= this.searchResults.length-1) return;
                this.focusId++;
            }
        },

        computed: {
            searchResults() {
                if (this.query.length < 2) return [];
                return this.clubberList.filter(clubber => {
                    return clubber.fullName.toLowerCase().includes(this.query.toLowerCase());
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "./../../assets/styles/main.scss";
    #searchBoxContainer.expanded {
        //left:calc(-100% + 135px);
        width: 400px;
        position: absolute;
        transition:width .25s;
        border-bottom-left-radius:0px !important;
        border-bottom-right-radius:0px !important;
        border-bottom-color:black !important;
        z-index:2;
        //-webkit-transition:width 2s;
    }
    
    #searchBoxContainer {
        //top:5px;
        //right:20px;
        transition:width .25s;
        position: absolute;
        right:20px;
        top:8px;
        width:125px;
    }

    @include desktop-only {
        #searchBoxContainer {
            width: 50px;
        }
    }

    .search-results {
        background-color:black;
        width:400px;
        position:absolute;
        color:white;
        z-index: 2;
        padding:5px;
        box-shadow:0px 0px 5px black;
        border-bottom-left-radius:5px;
        border-bottom-right-radius:5px;
        max-height:0px;
        overflow:hidden;
        display:none;

        transition:max-height .25s;
        transition-delay: .25s;

        h3 {
            padding:3px;
            padding-bottom:0px;
            margin-bottom:0px;
            // margin-top:-10px;
            text-align:center;
        }

        .result {
            padding:3px;
            border-radius: 10px;
        }

        .search-result-full {
            opacity: 1;
            transition: opacity .2s;
            margin-bottom: 10px;
            border-radius: 10px;
        }

        .search-result-full:hover {
            opacity: .75;
            transition: opacity .2s;
        }

        .search-result-full.focused {
            box-shadow: 0px 0px 10px 5px $success;
        }

        .Puggles{ background-color: $puggles; }
        .Cubbies{ background-color: $cubbies; }
        .Sparks{ background-color: $sparks; }
        .TnT{ background-color: $tnt; }
        .Trek{ background-color: $trek; }
        .Journey{ background-color: white; color:black;}
        .Leader{ background-color: white; color:black;}
        .Unknown{ background-color: wheat; color:black;}
    }

    .search-results.active {
        max-height:450px;
        display:block;
        overflow:auto;
        transition: max-height, display .25s;
        transition-delay: .25s;
    }

    @include touch {
        #searchBoxContainer.expanded {
            width:calc(100% - 75px);
            left:5px;
            top:10px;
        }

        .search-results {
            width:100%;
        }
    }
</style>
<template>
  <div>
    <b-navbar v-if="isLoggedIn && !isPublic" :mobile-burger="false" v-model="isMenuActive">

        <template slot="brand">
          <div class="level is-mobile" style="width:calc(100% - 5px);" v-if="!mobileSearchActive">
            <div class="level-left" v-if="$mq != 'desktop'">
              <b-button :icon-left="isMenuActive ? 'close' : 'menu'" type="is-primary" size="is-medium" @click="isMenuActive = !isMenuActive"/>
            </div>

            <div class="level-item has-text-centered">
              <b-navbar-item tag="router-link" :to="{ path: '/home' }">
                  <img
                      src="/images/shine_newLogo3_mobile.png"
                      alt="Shine Logo"
                  >
              </b-navbar-item>
            </div>

            <div class="level-right" v-if="$mq != 'desktop'">
              <b-button icon-left="magnify" type="is-primary" size="is-medium" @click="mobileSearchActive = true"/>
            </div>

          </div>

          <div v-if="mobileSearchActive" style="width:100%;">
                <search-box @search-box-closed="mobileSearchActive=false" class="is-pulled-left"/>
                <b-button 
                  icon-left="close" 
                  type="is-primary" 
                  size="is-medium" 
                  @click="mobileSearchActive = false" 
                  class="is-pulled-right"
                  style="margin-top:5px; margin-right:15px;"
                />
          </div>
        </template>

        <template slot="start">
            <!--
            <b-navbar-item tag="router-link" to="/home">
                HOME
            </b-navbar-item>
            -->

            <b-navbar-dropdown label="CLUBBERS" @click.native="toggleMobileDD('clubbers')">
              <span v-if="$mq == 'desktop' || mobileMenuActiveDD == 'clubbers'">
                <b-navbar-item tag="router-link" to="/newClubber">New Clubber</b-navbar-item>
                <b-navbar-item tag="router-link" to="/clubberList">Clubber List</b-navbar-item>
                <b-navbar-item tag="router-link" to="/archivedClubbers">Archived Clubbers</b-navbar-item>
                <b-navbar-item tag="router-link" to="/groups">Groups</b-navbar-item>
              </span>
            </b-navbar-dropdown>

            <b-navbar-dropdown label="ATTENDANCE" @click.native="toggleMobileDD('attendance')">
              <span v-if="$mq == 'desktop' || mobileMenuActiveDD == 'attendance'">
                <b-navbar-item tag="router-link" to="/checkIn">Check-In</b-navbar-item>
                <b-navbar-item tag="router-link" to="/checkOut">Check-Out</b-navbar-item>
                <b-navbar-item tag="router-link" to="/release">Release/Sign-Out Form</b-navbar-item>
                <b-navbar-item tag="router-link" to="/clubberList/attendance">Who's Here</b-navbar-item>
              </span>
            </b-navbar-dropdown>

            <b-navbar-dropdown label="BOOKWORK" @click.native="toggleMobileDD('bookwork')">
              <span v-if="$mq == 'desktop' || mobileMenuActiveDD == 'bookwork'">
                <b-navbar-item tag="router-link" to="/sections">Sections</b-navbar-item>
                <b-navbar-item tag="router-link" to="/topClubber">Top Clubber</b-navbar-item>
                <b-navbar-item tag="router-link" to="/awards">Awards</b-navbar-item>
              </span>
            </b-navbar-dropdown>

            <b-navbar-dropdown label="MANAGE" @click.native="toggleMobileDD('manage')">
              <span v-if="$mq == 'desktop' || mobileMenuActiveDD == 'manage'">
                <b-navbar-item tag="router-link" to="/manageUsers">Manage User Accounts</b-navbar-item>
                <b-navbar-item tag="router-link" to="/onlineRegistration">Manage Online Registration</b-navbar-item>
                <b-navbar-item tag="router-link" to="/pendingRegistrations">Pending Registrations</b-navbar-item>
                <b-navbar-item tag="router-link" to="/settings">Settings</b-navbar-item>
                <b-navbar-item tag="router-link" to="/importData">Import Clubbers</b-navbar-item>
              </span>
            </b-navbar-dropdown>

            <b-navbar-dropdown label="REPORTS/STATS" @click.native="toggleMobileDD('stats')">
              <span v-if="$mq == 'desktop' || mobileMenuActiveDD == 'stats'">
                <b-navbar-item tag="router-link" to="/stats">Stats</b-navbar-item>
                <b-navbar-item tag="router-link" to="/points">Points</b-navbar-item>
                <b-navbar-item tag="router-link" to="/birthdays">Birthdays</b-navbar-item>
                <b-navbar-item tag="router-link" to="/allergies">Allergies</b-navbar-item>
                <b-navbar-item tag="router-link" to="/paymentTracker">Payment Tracker</b-navbar-item>
                <b-navbar-item tag="router-link" to="/emails">Emails</b-navbar-item>
              </span>
            </b-navbar-dropdown>

            <b-navbar-item tag="router-link" to="/login">
                LOGOUT
            </b-navbar-item>
        </template>

        <template slot="end" v-if="$mq == 'desktop'">
            <search-box />
        </template>
    </b-navbar>
  </div>
</template>

<script>
import SearchBox from './SearchBox.vue'
export default {

  props: ['isPublic', 'isLoggedIn'],

  components: {
    SearchBox
  },

  data() {
    return {
      mobileMenuActive: false,
      mobileMenuActiveDD: "none",
      mobileSearchActive: false,
      overlayVisible: false,
      isMenuActive: false
    }
  },

  mounted() {
    this.imgSrc = this.$mq == "desktop" ? '/images/shine_newLogo3.png' : '/images/shine_newLogo3_mobile.png';

    this.$root.$on("new-page", () => {
      this.mobileMenuActive = false;
      this.overlayVisible = false;
      this.mobileMenuActiveDD = "none";
    });
  },
  watch: {
    mobileMenuActive(newVal) {
      this.overlayVisible = newVal;
    },
    mobileSearchActive(newVal) {
      if(newVal) this.mobileMenuActive = false;
    }
  },

  methods: {
    toggleMobileDD(mode) {
      if (this.mobileMenuActiveDD == mode) this.mobileMenuActiveDD = "none";
      else this.mobileMenuActiveDD = mode;
    },

    overlayClicked() {
      this.mobileMenuActive = false;
      this.overlayVisible = false;
      this.mobileMenuActiveDD = "none";
    }
  }
}
</script>
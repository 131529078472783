import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//import './registerServiceWorker'
import posthogPlugin from "./plugins/posthog";

import Buefy from "buefy";
Vue.use(Buefy);
import "../src/assets/styles/main.scss";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueMoment from "vue-moment";
Vue.use(VueMoment);

import VueWorker from "vue-worker";
Vue.use(VueWorker);

import Chartkick from "vue-chartkick";
import Chart from "chart.js";
Vue.use(Chartkick.use(Chart));

import VueMq from "vue-mq";

import * as Sentry from "@sentry/vue";

Vue.use(VueMq, {
  breakpoints: {
    phone: 500,
    tablet: 1023,
    desktop: Infinity,
  },
});

import VueHtmlToPaper from "vue-html-to-paper";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=no", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
};

Vue.use(VueHtmlToPaper, options);

if (import.meta.env.PROD) {
  Vue.prototype.$api = "/api/public/v1";
  Vue.prototype.$publicAPI = "/api/public";
  Vue.prototype.$uploadUrl = "/uploads";
} else {
  Vue.prototype.$api = "http://localhost:8081/v1";
  Vue.prototype.$publicAPI = "http://localhost:8081";
  Vue.prototype.$uploadUrl = "/uploads";
  Vue.config.devtools = true;
  Vue.config.performance = true;
}

Vue.prototype.$env = import.meta.env.MODE;

/* redirect to login page on 401 error */
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (router.currentRoute.name !== "login" && error.response.status === 401) {
      window.localStorage.setItem("loggedIn", false);
      window.localStorage.setItem("authToken", null);
      router.push({ path: "/login", params: { ret: router.currentRoute } });
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

Sentry.init({
  Vue,
  dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/shinerecordkeeping\.org/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

Vue.use(posthogPlugin);

// Vue.prototype.$http = axios;
Vue.prototype.$clubs = [
  "Puggles",
  "Cubbies",
  "Sparks",
  "T&T",
  "Trek",
  "Journey",
  "Leader",
  "Unknown",
];

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    formatDate(date) {
      const d = new Date();
      const offset = (-1 * d.getTimezoneOffset()) / 60;
      return this.$moment
        .utc(date)
        .utcOffset(offset)
        .format("dddd, MMMM Do YYYY h:mm:ss a");
    },

    clubYear() {
      const year =
        this.$moment().month() >= 7
          ? this.$moment().year()
          : this.$moment().year() - 1;
      const startDate = this.$moment(`${year}-07-01`).format("YYYY-MM-DD");
      const endDate = this.$moment(startDate).add(1, "year");
      return {
        start: startDate,
        end: endDate,
      };
    },
  },
});

router.afterEach((to, from) => {
  Vue.prototype.$posthog.capture("$pageleave", {
    $current_url: window.location.host + from.fullPath,
    path: from.fullPath,
  });
  Vue.prototype.$posthog.capture("$pageview", {
    $current_url: to.fullPath,
  });
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");


<div>
    <div class="control has-icons-left is-medium" id='searchBoxContainer' :class="{'expanded': active}">
        <input ref="searchBox" class="input search-box" type="text" autocomplete="nope" placeholder="Search Clubber Names..." v-model="query" 
            @focus="grow()" @blur="shrink()" @keyup.esc="$refs.searchBox.blur()" 
            @keyup.up="tabIdxUp()" @keyup.down="tabIdxDown()" 
            @keyup.enter="goToClubber(searchResults[focusId].id)"
        />
        <b-icon icon="magnify" size="is-small" class="is-left" />
        <div class="search-results" :class="{'active': resultsBoxVisible}" @mouseover="mouseover=true" @mouseout="mouseover=false">{{searchResultsMsg}}<br/><br/>
            <div class="search-result-full is-clickable" :class="{'focused': focusId === i}"
                v-for="(result, i) in searchResults" :key="i" @click="goToClubber(result.id)" 
            >
                <div class="content result" :class="clubClass(result)">
                    <h3>{{result.fullName}}</h3>
                    <div>
                        <div>
                            <i class="fas fa-phone"></i> {{result.family.phone1}} 
                            <span v-if="result.family.phone1note.length > 0">({{ result.family.phone1note }})</span>
                        </div>
                        <div>
                            <i class="fas fa-phone"></i> {{result.family.phone2}} 
                            <span v-if="result.family.phone2note.length > 0">({{ result.family.phone2note }})</span>
                        </div>
                        <div>
                            <i class="fas fa-phone"></i> {{result.family.phone3}} 
                            <span v-if="result.family.phone3note.length > 0">({{ result.family.phone3note }})</span>
                        </div>
                        <i class="fas fa-address-book"></i> {{result.family.address.str}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<template>
    <div class="notification specialNotification" ref="alertBox"
        :class="{'is-danger': type==='danger', 'is-info': type==='info', 'is-success': type==='success'}"
        v-if="visible">
        <button class="delete" @click="visible=false"></button>
        {{message}}
    </div>
</template>

<script>
    export default {
        data() {
            return {
                message: "...",
                visible: false,
                type: 'info'
            }
        },

        watch: {
            '$route': function() {
                this.visible = false;
            }
        },

        mounted() {
            this.$root.$on('show-alert', (type,message) => {
                this.type = type;
                this.message = message;
                this.visible = true;
                /*
                var options = {
                    container: "body",
                    duration: 250,
                    easing: "ease-in",
                    offset: 0,
                    cancelable: false,
                    onStart: false,
                    onDone: false,
                    onCancel: false,
                    x: false,
                    y: true
                };
                */
                window.scrollTo(0,0);
                this.startTimer();
            });

            this.$root.$on('hide-alert', () => {
                this.visible = false;
            });
        },

        methods: {
            startTimer: function() {
                setTimeout(() => {
                    this.$root.$emit('hide-alert');
                }, 10000);
            }
        }

    }
</script>

<style>
    .notification.specialNotification {
        position: absolute;
        width: 100%;
        z-index: 1;
        box-shadow: 1px 0px 5px black;
    }
</style>
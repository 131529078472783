
<div>
  <section class="page-section" :class="$mq">
    <div class="page-container" :class="$mq">
      <section
        class="hero is-primary has-text-centered"
        style="margin-bottom:0px;"
        v-if="isPublic && $route.name !== 'home'"
      >
        <figure class="image has-text-centered">
          <img :src="this.imgSrc" class="logo" />
        </figure>

      </section>

      <navbar :is-public="isPublic" :is-logged-in="isLoggedIn"/>

      <section class="hero is-small page-title" v-if="pageTitle !== false">
        <h1 class="title">
          <a
            class="button is-pulled-left is-black"
            @click="goBack()"
            v-if="(isLoggedIn==='true' && !isPublic) || hasBackButton"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          {{pageTitle}}
        </h1>
      </section>

      <alert-box></alert-box>

      <transition :name="transitionName" mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </div>
  </section>
  </div>

<template>
<div>
  <section class="page-section" :class="$mq">
    <div class="page-container" :class="$mq">
      <section
        class="hero is-primary has-text-centered"
        style="margin-bottom:0px;"
        v-if="isPublic && $route.name !== 'home'"
      >
        <figure class="image has-text-centered">
          <img :src="this.imgSrc" class="logo" />
        </figure>

      </section>

      <navbar :is-public="isPublic" :is-logged-in="isLoggedIn"/>

      <section class="hero is-small page-title" v-if="pageTitle !== false">
        <h1 class="title">
          <a
            class="button is-pulled-left is-black"
            @click="goBack()"
            v-if="(isLoggedIn==='true' && !isPublic) || hasBackButton"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          {{pageTitle}}
        </h1>
      </section>

      <alert-box></alert-box>

      <transition :name="transitionName" mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </div>
  </section>
  </div>
</template>

<script>
import Navbar from "./components/Global/Navbar2.vue";
import AlertBox from "./components/Global/AlertBox.vue";
import _ from 'lodash';

export default {
  name: "app",

  components: {
    Navbar,
    AlertBox
  },

  data() {
    return {
      pageTitle: "",
      isLoggedIn: window.localStorage.getItem("loggedIn")=='true',
      transitionName: "component-slide-fade",
      infoBoxVisible: false,
      hasBackButton: false,
      isPublic: false,
      imgSrc: "",
      overlayVisible: false
    };
  },

  watch: {
    mobileMenuActive() {
      this.overlayVisible = this.mobileMenuActive;
    },

    $route(to, from) {
      this.$root.$emit("new-page");
      this.getInfoFromRoute();
      this.checkForUpdates();
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      if (fromDepth > 2 && toDepth > 2) {
        this.transitionName = "";
      } else {
        this.transitionName = "component-slide-fade";
      }

      if (to.name == "login") {
        this.$root.$emit('is-logged-out');
      }
    }
  },

  mounted() {

    setInterval(() => {
      if (this.isLoggedIn) {
        this.checkLogin();
      }
    }, 30000);

    this.$root.$on("is-logged-out", () => {
      this.isLoggedIn = false;
    });

    this.$root.$on("show-status-alert", type => {

      let msg;
      if(type == 'error') {
        msg = "Sorry, there was an error.";
        type = 'is-danger';
      }
      if(type == 'success') {
        msg = "Success!";
        type = 'is-success';
      }

      this.$buefy.toast.open({
          message: msg,
          type: type
      });

    });

    this.$root.$on("handle-error", error => {
      
      let msg = '';
      if (_.has(error, 'response')) {
        // 401 redirect to login handled in axios interceptor in main.js
        if (error.response.status === 401) return;

        msg = error.response.data.length > 0 ? error.response.data
          : 'Sorry, there was an error.';    
      } else {
        msg = 'Sorry, there was an error.';
      }

      this.$buefy.toast.open({
        message: `Error: ${msg}`,
        type: "is-danger"
      });

      if (this.$env != "production") {
        console.log(error);
      }    

    });

  },

  methods: {

    goBack() {
      this.$router.go(-1);
    },

    checkLogin() {
      this.$http.get(`${this.$api}/checkLogin`).catch(() => {
        window.localStorage.setItem('loggedIn', false);
        window.localStorage.setItem('authToken', null);
        this.$router.push({ path: '/login', params: { ret: this.$router.currentRoute } });
      })
    },

    checkForUpdates() {
      // force page reload if there are new updates
      const lastReload = window.localStorage.getItem('lastReload');
      if (lastReload !== null) {
        this.$http.get(this.$publicAPI+'/lastUpdate').then(response => {
          const lastServerUpdate = response.data;
          if (this.$moment.unix(lastServerUpdate).isAfter(lastReload)) {
            window.localStorage.setItem('lastReload', this.$moment().format());
            window.location.reload();
          } else {
            window.localStorage.setItem('lastReload', this.$moment().format());
          }
        })
      } else {
          window.localStorage.setItem('lastReload', this.$moment().format());
      }
    },

    getInfoFromRoute() {
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + window.localStorage.getItem("authToken");
      this.$http.defaults.headers.common["utcOffset"] = this.$moment().utcOffset();
      this.isLoggedIn = (window.localStorage.getItem("loggedIn") == 'true');
      this.pageTitle = this.$router.currentRoute.meta.title;
      this.hasBackButton = this.$router.currentRoute.meta.hasBackButton;
      this.isPublic = this.$router.currentRoute.meta.public;
      const pageName = this.$router.currentRoute.name;
      if (this.isLoggedIn && !this.isPublic) this.logPageView(pageName);
      this.getLogo();
    },

    getLogo() {
      if (this.$route.name === 'register') {
        var uniqueId = this.$router.currentRoute.params.uniqueId;
        this.$http.get(`${this.$publicAPI}/onlineReg/settings/${uniqueId}`).then(response => {
          this.pageTitle = `Register for Awana @ ${response.data.name}`;
          this.imgSrc = `${this.$uploadUrl}/${response.data.image}`;
        }).catch(() => {
          if (this.$mq === 'phone') {
            this.imgSrc = '/images/shine_newLogo3_mobile.png';
          } else {
            this.imgSrc = '/images/shine_newLogo3.png';
          }
        });
      } else {
        if (this.$mq === 'phone') {
          this.imgSrc = '/images/shine_newLogo3_mobile.png';
        } else {
          this.imgSrc = '/images/shine_newLogo3.png';
        }
      }
    },

    logPageView(pageName) {
      if (pageName === undefined) return;
      const data = {
        screenSize: `${window.screen.width}x${window.screen.height}`,
        pageTitle: pageName
      };
      this.$http.post(this.$api+'/pageView', data);
    }
  }
};
</script>

<style lang="scss">
@import './assets/styles/main.scss';

.logo {
  width: auto !important;
  height:100px !important;
  margin: 10px auto;
}

.page-container {
  height:99vh;
}
</style>
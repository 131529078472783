import Vue from "vue";
// import { $nextTick } from "vue";
import Router from "vue-router";
import posthog from "./plugins/posthog";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: import.meta.env.BASE_URL,
  routes: [
    /* Public Routes */
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Public/Home.vue"),
      meta: { title: false, public: true, hasBackButton: false },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Public/Login.vue"),
      meta: { title: "Login", public: true, hasBackButton: true },
    },
    {
      path: "/register/:uniqueId",
      name: "register",
      component: () => import("@/views/Public/Register.vue"),
      meta: { title: "Register for Awana", public: true, hasBackButton: false },
      props: { kiosk: false },
    },
    {
      path: "/register/:uniqueId/kiosk",
      name: "register",
      component: () => import("@/views/Public/Register.vue"),
      meta: { title: "Register for Awana", public: true, hasBackButton: false },
      props: { kiosk: true },
    },
    {
      path: "/demo",
      name: "demo",
      component: () => import("@/views/Public/Demo.vue"),
      meta: { title: "Sign-Up for a Demo", public: true, hasBackButton: true },
    },
    {
      path: "/resetPassword",
      name: "resetPassword",
      component: () => import("@/views/Public/ResetPassword.vue"),
      meta: { title: "Reset Password", public: true, hasBackButton: true },
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("@/views/Public/Contact.vue"),
      meta: { title: "Contact", public: true, hasBackButton: true },
    },

    /* Protected Routes */
    {
      path: "/home",
      name: "protectedHome",
      component: () => import("@/views/Protected/Home.vue"),
      meta: { title: "Home", public: false, hasBackButton: true },
    },
    {
      path: "/newClubber",
      name: "newClubber",
      component: () => import("@/views/Protected/NewClubber.vue"),
      meta: { title: "New Clubber", public: false, hasBackButton: true },
    },
    {
      path: "/viewClubber/:clubberId",
      name: "viewClubber",
      component: () => import("@/views/Protected/ViewClubber.vue"),
      meta: { title: "View Clubber", public: false, hasBackButton: true },
    },
    {
      path: "/editClubber/:clubberId",
      name: "editClubber",
      component: () => import("@/views/Protected/NewClubber.vue"),
      meta: { title: "Edit Clubber", public: false, hasBackButton: true },
    },
    {
      path: "/clubberList",
      name: "clubberList",
      component: () => import("@/views/Protected/ClubberList.vue"),
      meta: { title: "Clubber List", public: false, hasBackButton: true },
      children: [
        {
          path: "",
          name: "clubberListByClub",
          component: () => import("@/views/Protected/ClubberList/ByClub.vue"),
          meta: { title: "Clubber List", public: false, hasBackButton: true },
        },
        {
          path: "club",
          name: "clubberListByClub2",
          component: () => import("@/views/Protected/ClubberList/ByClub.vue"),
          meta: { title: "Clubber List", public: false, hasBackButton: true },
        },
        {
          path: "family",
          name: "clubberListByFamily",
          component: () => import("@/views/Protected/ClubberList/ByFamily.vue"),
          meta: { title: "Clubber List", public: false, hasBackButton: true },
        },
        {
          path: "attendance",
          name: "clubberListInAttendance",
          component: () =>
            import("@/views/Protected/ClubberList/ByAttendance.vue"),
          meta: { title: "Clubber List", public: false, hasBackButton: true },
        },
        {
          path: "attendance/:date",
          name: "clubberListInAttendance2",
          component: () =>
            import("@/views/Protected/ClubberList/ByAttendance.vue"),
          meta: { title: "Clubber List", public: false, hasBackButton: true },
        },
        {
          path: "details",
          name: "clubberListDetails",
          component: () => import("@/views/Protected/ClubberList/Details.vue"),
          meta: { title: "Clubber List", public: false, hasBackButton: true },
        },
      ],
    },
    {
      path: "/checkIn",
      name: "checkIn",
      component: () => import("@/views/Protected/CheckIn.vue"),
      meta: { title: "Check-In", public: false, hasBackButton: true },
    },
    {
      path: "/checkOut",
      name: "checkOut",
      component: () => import("@/views/Protected/CheckOut.vue"),
      meta: { title: "Check-Out", public: false, hasBackButton: true },
    },
    {
      path: "/manageUsers",
      name: "manageUsers",
      component: () => import("@/views/Protected/UserAccounts.vue"),
      meta: { title: "Manage Users", public: false, hasBackButton: true },
    },
    {
      path: "/onlineRegistration",
      name: "onlineRegistration",
      component: () => import("@/views/Protected/OnlineRegistration.vue"),
      meta: {
        title: "Manage Online Registration",
        public: false,
        hasBackButton: true,
      },
    },
    {
      path: "/pendingRegistrations",
      name: "pendingRegistrations",
      component: () => import("@/views/Protected/PendingRegistrations.vue"),
      meta: {
        title: "Pending Registrations",
        public: false,
        hasBackButton: true,
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("@/views/Protected/Settings.vue"),
      meta: { title: "Settings", public: false, hasBackButton: true },
    },
    {
      path: "/importData",
      name: "importData",
      component: () => import("@/views/Protected/ImportData.vue"),
      meta: { title: "Import Data", public: false, hasBackButton: true },
    },
    {
      path: "/archivedClubbers",
      name: "archivedClubbers",
      component: () => import("@/views/Protected/ArchivedClubbers.vue"),
      meta: { title: "Archived Clubbers", public: false, hasBackButton: true },
    },
    {
      path: "/groups",
      name: "groups",
      component: () => import("@/views/Protected/Groups.vue"),
      meta: { title: "Manage Groups", public: false, hasBackButton: true },
    },
    {
      path: "/release",
      name: "release",
      component: () => import("@/views/Protected/Release.vue"),
      meta: {
        title: "Print Sign-Out Sheet",
        public: false,
        hasBackButton: true,
      },
    },
    {
      path: "/sections",
      name: "sections",
      component: () => import("@/views/Protected/Sections.vue"),
      meta: { title: "Sections", public: false, hasBackButton: true },
    },
    {
      path: "/awards",
      name: "awards",
      component: () => import("@/views/Protected/Awards.vue"),
      meta: { title: "Awards", public: false, hasBackButton: true },
    },
    {
      path: "/topClubber",
      name: "topClubber",
      component: () => import("@/views/Protected/TopClubber.vue"),
      meta: { title: "Top Clubber", public: false, hasBackButton: true },
    },
    {
      path: "/points",
      name: "points",
      component: () => import("@/views/Protected/Points.vue"),
      meta: { title: "Points", public: false, hasBackButton: true },
    },
    {
      path: "/birthdays",
      name: "birthdays",
      component: () => import("@/views/Protected/Birthdays.vue"),
      meta: { title: "Birthdays", public: false, hasBackButton: true },
    },
    {
      path: "/allergies",
      name: "allergies",
      component: () => import("@/views/Protected/Allergies.vue"),
      meta: { title: "Allergies", public: false, hasBackButton: true },
    },
    {
      path: "/paymentTracker",
      name: "paymentTracker",
      component: () => import("@/views/Protected/Payment.vue"),
      meta: { title: "Payment Tracker", public: false, hasBackButton: true },
    },
    {
      path: "/emails",
      name: "emails",
      component: () => import("@/views/Protected/Emails.vue"),
      meta: { title: "Emails", public: false, hasBackButton: true },
    },
    {
      path: "/stats",
      name: "stats",
      component: () => import("@/views/Protected/Stats.vue"),
      meta: { title: "Stats", public: false, hasBackButton: true },
      children: [
        {
          path: "",
          name: "attendanceStats",
          component: () => import("@/views/Protected/Stats/Attendance.vue"),
          meta: { title: "Stats", public: false, hasBackButton: true },
        },
        {
          path: "attendance",
          name: "attendanceStats",
          component: () => import("@/views/Protected/Stats/Attendance.vue"),
          meta: { title: "Stats", public: false, hasBackButton: true },
        },
        {
          path: "clubs",
          name: "clubStats",
          component: () => import("@/views/Protected/Stats/Clubs.vue"),
          meta: { title: "Stats", public: false, hasBackButton: true },
        },
        {
          path: "sections",
          name: "sectionsStats",
          component: () => import("@/views/Protected/Stats/Sections.vue"),
          meta: { title: "Stats", public: false, hasBackButton: true },
        },
        {
          path: "checkIn",
          name: "checkInTimes",
          component: () => import("@/views/Protected/Stats/CheckIn.vue"),
          meta: { title: "Stats", public: false, hasBackButton: true },
        },
      ],
    },

    {
      path: "/admin",
      component: () => import("@/views/Admin/Admin.vue"),
      meta: { title: "Admin", public: false, hasBackButton: true },
    },

    /* 404 Error */
    {
      path: "*",
      name: "pageNotFound",
      component: () => import("@/views/Public/PageNotFound.vue"),
      meta: { title: "Page Not Found", public: true, hasBackButton: true },
    },
  ],
  afterEach: (to, from) => {
    console.log('test');
    nextTick(() => {
      console.log("afterEach", from.fullPath, to.fullPath);
      posthog.capture("$pageleave", {
        $current_url: window.location.host + from.fullPath,
        path: from.fullPath,
      });
      posthog.capture("$pageview", {
        $current_url: to.fullPath,
      });
    });
  },
});
